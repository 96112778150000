/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
* Copyright 2022 Adobe
* All Rights Reserved.
*
* NOTICE: All information contained herein is, and remains
* the property of Adobe and its suppliers, if any. The intellectual
* and technical concepts contained herein are proprietary to Adobe
* and its suppliers and are protected by all applicable intellectual
* property laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe.
**************************************************************************/
import VersionMap from "interface/public/VersionMap";

const loadSDKScript = (scriptName = "latest.js", version = VersionMap.latest, interfaceScript = "View") => {
    const scripts = Array.from(document.scripts);
    const scriptPath = `view-sdk/${scriptName}`;
    const scriptSrc = scripts.find(script => script.src && script.src.endsWith(scriptPath)).src;
    const ownSrc = scriptSrc.substring(0, scriptSrc.indexOf(scriptName));
    const sdkSrc = `${`${ownSrc + version}/${interfaceScript}`}SDKInterface.js`;
    const script = document.createElement("script");
    script.async = false;
    script.setAttribute("src", sdkSrc);
    document.head.appendChild(script);
};

const getLoadedScript = () => {
    const scripts = Array.from(document.scripts);
    // eslint-disable-next-line no-restricted-syntax
    for (const script of scripts) {
        const regex = /\/view-sdk\/(edit|latest|main|viewer).js$/;
        const found = script.src.match(regex);
        if (found) return found[1];
    }
    return "main";
};

export {
    loadSDKScript,
    getLoadedScript,
};
