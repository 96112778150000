/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
* Copyright 2022 Adobe
* All Rights Reserved.
*
* NOTICE: All information contained herein is, and remains
* the property of Adobe and its suppliers, if any. The intellectual
* and technical concepts contained herein are proprietary to Adobe
* and its suppliers and are protected by all applicable intellectual
* property laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe.
**************************************************************************/

const VersionMap = Object.freeze({
    latest: "__VERSION__",
    public: "3.27.1_3.2.10-ea8924cb",
    legacy: "3.27.1_3.2.10-ea8924cb",
    edit: "2.24.4_2.12.0-7d0a8c15",
});

export default VersionMap;
